import React from "react"
import { Helmet } from "react-helmet"
import { Link, Notification } from "gatsby-interface"

import Layout from "../../layouts"
import Header from "../../components/header"
import Container from "../../components/container"
import PageWithSidebar from "../../components/page-with-sidebar"
import NewStickyResponsiveSidebar from "../../components/sidebar/new-sticky-responsive-sidebar"
import { getCurrentDocsSection } from "../../utils/sidebar/item-list.js"
import { SkipNavTarget } from "../../components/shared/components/SkipNav"

import allDocs from "../../components/sidebar/all-docs-sidebar.yaml"
import DocsHomeCtaCallout from "../../components/docs/docs-home-cta-callout.js"
import {
  SubsectionList,
  SectionList,
  PageHeader,
  LinkList,
} from "../../components/docs/docs-type-landing-section.js"
import { GatsbyCloudLogo } from "../../components/shared/logos"
import FooterLinks from "../../components/shared/footer-links.js"

const DocsTypeTemplate = ({ location, pageContext }) => {
  const docsHomepageContent = pageContext?.docsHomepageContent
  const documentationType =
    pageContext?.documentationType || allDocs[0].subItems.slice(2) // How to Guides
  const isDocsHomepage = !!docsHomepageContent

  const headerContent = isDocsHomepage ? allDocs[0].content : null

  const currentPage =
    allDocs[0].subItems.find(
      subItem => documentationType.label === subItem.label
    ) || allDocs[0]

  const {
    docTypeDescription,
    subItems,
    label,
    title,
    subSections,
    emphasizedColor,
  } = currentPage

  const sectionList = isDocsHomepage
    ? allDocs[0].subItems.filter(item => !item.itemType).slice(2)
    : subItems.filter(item => !item.itemType)

  return (
    <Layout>
      <Header
        applyTopPadding={false}
        isFullWidth
        isFixed
        docType={getCurrentDocsSection(location.pathname)}
      />
      <PageWithSidebar
        location={location}
        SidebarComponent={NewStickyResponsiveSidebar}
      >
        <Helmet title={headerContent?.title || label} />
        <main>
          <SkipNavTarget />
          <PageHeader
            emphasizedColor={emphasizedColor}
            titleText={headerContent?.title || title || label}
            description={headerContent?.description || docTypeDescription}
            location={location}
          />
          <Container>
            {isDocsHomepage && (
              <DocsHomeCtaCallout
                content={headerContent.gettingStartedCallout}
              />
            )}
            {(location.pathname === "/docs/tutorial" ||
              location.pathname === "/docs/tutorial/") && (
              <Notification
                css={theme => ({ marginBottom: theme.space[7] })}
                tone="BRAND"
                variant="SECONDARY"
                content={
                  <span>
                    This tutorial is intended to be as accessible as possible to
                    people without much web development experience. If you
                    prefer to jump straight to code, feel free to skip to{" "}
                    <Link
                      to="/docs/quick-start"
                      css={theme => ({
                        fontWeight: theme.fontWeights.bold,
                      })}
                    >
                      the quick start
                    </Link>
                    .
                  </span>
                }
              />
            )}
            {subSections ? (
              <SubsectionList
                currentPage={currentPage}
                advanced={currentPage.subItems.find(item => item?.advanced)}
              />
            ) : currentPage.subItems.find(item => item?.subItems) ? (
              <SectionList
                sectionList={sectionList}
                isDocsHomepage={isDocsHomepage}
              />
            ) : (
              <LinkList
                items={currentPage.subItems.filter(item => !item.itemType)} // exclude both overview & additional
              />
            )}
            {isDocsHomepage && (
              <div
                css={theme => ({
                  background: theme.colors.purple[5],
                  padding: theme.space[6],
                  marginBottom: theme.space[6],
                  verticalAlign: `middle`,
                  "& > svg": { verticalAlign: `middle` },
                })}
              >
                <GatsbyCloudLogo width={32} height={32} />
                &nbsp; Looking for the Gatsby Cloud documentation? Head on over
                to the{" "}
                <Link href="https://support.gatsbyjs.com/">
                  Gatsby Cloud Knowledge Base &rarr;
                </Link>
              </div>
            )}
            <FooterLinks />
          </Container>
        </main>
      </PageWithSidebar>
    </Layout>
  )
}

export default DocsTypeTemplate
